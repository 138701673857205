import { setDefaultBaseUrl } from "./helpers/axios.helper";
import React from "react";
import { createRoot } from "react-dom"; // Koristimo createRoot iz react-dom
import "./index.css";
import App from "./App";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/fonts/RedHatDisplay-VariableFont_wght.ttf";

import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en.json";
import srbTranslation from "./locales/srb.json";
import store from "./store";
import { Provider } from "react-redux";
import ThemeContext from "../src/theme/ThemeContext";
import theme from "../src/theme/theme";

setDefaultBaseUrl();

const savedLanguage = localStorage.getItem('language') || 'srb';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    srb: { translation: srbTranslation },
  },
  lng: savedLanguage, // Jezik se postavlja iz localStorage ako postoji
  fallbackLng: 'srb', // Podrazumevani jezik
  interpolation: {
    escapeValue: false, // React automatski escape-uje vrednosti
  },
});


const root = createRoot(document.getElementById("root")); // Koristimo createRoot
document.body.style.backgroundColor = theme.colors.background;

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/sw.js').then(function (registration) {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function (err) {
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}

root.render(
  <Provider store={store}>
    <ThemeContext.Provider value={theme}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </ThemeContext.Provider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();